import { get } from '@/api/product'
import { uploadFile } from '@/api/quote'

const product = {
  namespaced: true,
  state: {
    product: {},
    variant: {}
  },
  actions: {
    get ({commit}, id) {
      return new Promise((resolve, reject) => {
        get(id).then(res => {
          commit('SET_PRODUCT', res.data.data)
          resolve()
        }).catch(err => {
          reject(err)
        })
      })
    },
    set ({commit}, data) {
      commit('SET_PRODUCT', data)
    },
    setVariant ({commit}, option) {
      commit('SET_VARIANT', option)
    },
    async uploadFile ({commit}, data) {
      const uploadedFile = await uploadFile(data)
      return uploadedFile.data
    }
  },
  mutations: {
    SET_PRODUCT: (state, data) => {
      if (data) {
        state.product = data
        state.product.ship_price_percent = 0
        state.product.minimal_shipping_quantity = 0
        state.product.maximum_shipping_quantity = 0
        state.product.allow_note = false
        state.product.note_header = ""
        for(var i = 0; i < state.product.metafields.edges.length; i ++) {
          var edge = state.product.metafields.edges[i]
          if(edge.node.key == "ship_price_percent") {
            state.product.ship_price_percent = Number(edge.node.value)
            break
          }
        }
        for(var i = 0; i < state.product.metafields.edges.length; i ++) {
          var edge = state.product.metafields.edges[i]
          if(edge.node.key == "minimal_shipping_quantity") {
            state.product.minimal_shipping_quantity = Number(edge.node.value)
            break
          }
        }
        for(var i = 0; i < state.product.metafields.edges.length; i ++) {
          var edge = state.product.metafields.edges[i]
          if(edge.node.key == "maximum_shipping_quantity") {
            state.product.maximum_shipping_quantity = Number(edge.node.value)
            break
          }
        }
        for(var i = 0; i < state.product.metafields.edges.length; i ++) {
          var edge = state.product.metafields.edges[i]
          if(edge.node.key == "allow_note") {
            state.product.allow_note = edge.node.value === "true"
            break
          }
        }
        for(var i = 0; i < state.product.metafields.edges.length; i ++) {
          var edge = state.product.metafields.edges[i]
          if(edge.node.key == "note_header") {
            state.product.note_header = edge.node.value
            break
          }
        }
        state.variant = data.variants.edges[0].node
      }
      console.log('product data: ', data)
    },
    SET_VARIANT: (state, option) => {
      if (option) {
        let selected_options = JSON.parse(JSON.stringify(state.variant.selectedOptions))
        let index = selected_options.findIndex(opt => opt.name === option.name)
        selected_options[index] = option
        let variant = state.product.variants.edges.find(edge => JSON.stringify(selected_options) === JSON.stringify(edge.node.selectedOptions))
        if (variant) {
          state.variant = variant.node
        }
      }
    }
  },
  getters: {
    get (state) {
      return state.product
    },
    options (state) {
      return state.product.options
    },
    variants (state) {
      return state.product.variants
    },
    variant (state) {
      return state.variant
    }
  }
}

export default product
