<template>
	<svg width="34" height="25" viewBox="0 0 34 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M32.9189 3.0811C29.2973 2.91894 25.2973 4.91894 22.9729 6.48651C21.6756 7.35137 20.1081 7.94596 18.7027 8.32434L17.6216 6.10813C19.081 5.72975 20.7027 5.13515 22 4.27029C24.3243 2.64867 28.3783 0.702722 31.9459 0.81083C32.3783 0.81083 33.3513 0.864884 33.6216 0.918938V3.13515C33.3513 3.0811 33.1892 3.0811 32.9189 3.0811Z" fill="#ED1C24"/>
    <path d="M24.973 0C23.5676 0.648649 22.2703 1.35135 21.2433 2.05405C19.8379 3.02703 18.1082 3.62162 16.5947 4L15.5676 1.72973C16.973 1.40541 18.6487 0.864865 20.0001 0H24.973Z" fill="#ED1C24"/>
    <path d="M0 19.2973C2.21622 19.1352 6 18.2703 8.48649 16.3784C11.1351 14.3784 14.0541 13.2433 17.3513 13.2433C18.973 13.2433 22.3243 12.5946 24.6486 10.973C26.7568 9.51354 30.3243 7.72976 33.6216 7.56759V5.24327C30.0541 5.08111 26 7.08111 23.6757 8.70273C21.3514 10.3244 18 10.973 16.3784 10.973C13.0811 10.973 10.1622 12.1622 7.51351 14.1081C5.35135 15.7298 2.21622 16.5946 0 16.9189L0 19.2973Z" fill="#ED1C24"/>
    <path d="M0 14.5946C2.16216 14.2162 4.86486 13.3513 6.81081 11.8919C9.45946 9.89189 12.3784 8.75676 15.6757 8.75676C16.4324 8.75676 17.5135 8.59459 18.7027 8.32432L14.7568 0H0V14.5946Z" fill="#17479E"/>
    <path d="M11.7297 19.7297C13.9459 18.4324 16.3243 17.6757 18.9189 17.6757C20.5405 17.6757 23.8919 17.027 26.2162 15.4054C28 14.1622 30.8108 12.7027 33.5676 12.1622V9.72974C30.4324 10.1081 27.2432 11.7297 25.2432 13.0811C22.9189 14.7027 19.5676 15.3514 17.9459 15.3514C14.6486 15.3514 11.7297 16.5405 9.08108 18.4865C8.48648 18.9189 7.83783 19.2973 7.13513 19.6757L11.7297 19.7297Z" fill="#ED1C24"/>
    <path d="M28.054 19.7297C29.5135 18.7567 31.4594 17.7297 33.6216 17.0811V14.5405C31.027 15.1892 28.5405 16.4865 26.9189 17.6216C25.1892 18.8108 22.973 19.4594 21.2432 19.7297H28.054Z" fill="#ED1C24"/>
    <path d="M1.5135 12.2703L1.56756 11.6757L2.05404 12.1081L2.64864 12L2.37837 12.5405L2.64864 13.0811L2.05404 13.027L1.56756 13.4054L1.5135 12.8108L0.972961 12.5405L1.5135 12.2703Z" fill="white"/>
    <path d="M4.75673 11.027L4.81078 10.4324L5.29727 10.8648L5.89186 10.7567L5.62159 11.2972L5.89186 11.8378L5.29727 11.7837L4.81078 12.1621L4.75673 11.5675L4.21619 11.2972L4.75673 11.027Z" fill="white"/>
    <path d="M7.78383 9.29735L7.89194 8.70276L8.32437 9.08114L8.91896 9.02708L8.64869 9.56762L8.91896 10.1082L8.32437 10.0001L7.89194 10.4325L7.78383 9.83789L7.24329 9.56762L7.78383 9.29735Z" fill="white"/>
    <path d="M11.1352 7.67565L11.1892 7.08105L11.6217 7.51349L12.2703 7.40538L11.946 7.94592L12.2703 8.48646L11.6757 8.37835L11.1892 8.81078L11.0811 8.21619L10.5406 7.94592L11.1352 7.67565Z" fill="white"/>
    <path d="M15.0811 7.08105L15.1352 6.48645L15.5676 6.91888L16.2162 6.81077L15.8919 7.35132L16.2162 7.89186L15.6216 7.8378L15.1352 8.21618L15.0271 7.62159L14.4865 7.35132L15.0811 7.08105Z" fill="white"/>
    <path d="M12.4864 5.29735L12.5945 4.70276L13.0269 5.13519L13.6215 5.02708L13.3513 5.56762L13.6215 6.10816L13.0269 6.00006L12.5945 6.43249L12.4864 5.83789L11.9459 5.56762L12.4864 5.29735Z" fill="white"/>
    <path d="M8.5406 6.05407L8.64871 5.45947L9.08114 5.8919L9.67574 5.7838L9.40547 6.32434L9.67574 6.86488L9.08114 6.81082L8.64871 7.24326L8.5406 6.59461L8.00006 6.32434L8.5406 6.05407Z" fill="white"/>
    <path d="M5.24318 7.83788L5.35129 7.24329L5.78372 7.62166L6.37831 7.56761L6.10804 8.10815L6.37831 8.64869L5.78372 8.54058L5.35129 8.97302L5.24318 8.37842L4.70264 8.10815L5.24318 7.83788Z" fill="white"/>
    <path d="M2.21624 9.62169L2.27029 9.0271L2.70272 9.40548L3.35137 9.35142L3.02705 9.89196L3.35137 10.4325L2.75678 10.3244L2.27029 10.7568L2.16218 10.1622L1.62164 9.89196L2.21624 9.62169Z" fill="white"/>
    <path d="M0 7.24329L0.432432 7.67572L1.08108 7.56761L0.810811 8.10815L1.08108 8.64869L0.486486 8.59464L0 9.02707V7.24329Z" fill="white"/>
    <path d="M2.97304 6.54052L3.08114 5.94592L3.51358 6.37836L4.10817 6.27025L3.8379 6.81079L4.10817 7.35133L3.51358 7.24322L3.08114 7.67565L2.97304 7.08106L2.4325 6.81079L2.97304 6.54052Z" fill="white"/>
    <path d="M6.10811 4.70275L6.16216 4.10815L6.64865 4.54059L7.24324 4.43248L6.97297 4.97302L7.24324 5.51356L6.64865 5.45951L6.16216 5.89194L6.10811 5.24329L5.56757 4.97302L6.10811 4.70275Z" fill="white"/>
    <path d="M9.40541 3.1351L9.45946 2.48645L9.94595 2.91888L10.5405 2.86483L10.2703 3.40537L10.5405 3.94591L9.94595 3.8378L9.45946 4.27023L9.40541 3.67564L8.86487 3.40537L9.40541 3.1351Z" fill="white"/>
    <path d="M13.3514 2.59459L13.4595 2L13.8919 2.43243L14.4865 2.32432L14.2163 2.86487L14.4865 3.40541L13.8919 3.2973L13.4595 3.72973L13.3514 3.13514L12.8109 2.86487L13.3514 2.59459Z" fill="white"/>
    <path d="M12.4865 0L12.3243 0.378378L12.5946 0.918919L12 0.810811L11.5135 1.24324L11.4054 0.648649L10.8649 0.378378L11.4595 0.108108V0H12.4865Z" fill="white"/>
    <path d="M7.51354 0.756704L7.56759 0.162109L8.00003 0.594542L8.64868 0.486434L8.37841 1.02697L8.64868 1.56751L8.05408 1.51346L7.56759 1.89184L7.45949 1.29724L6.91895 1.02697L7.51354 0.756704Z" fill="white"/>
    <path d="M4.27034 2.54052L4.37845 1.94592L4.81088 2.3243L5.40547 2.27025L5.1352 2.81079L5.40547 3.35133L4.81088 3.24322L4.37845 3.67565L4.27034 3.08106L3.7298 2.81079L4.27034 2.54052Z" fill="white"/>
    <path d="M1.08107 4.37839L1.18918 3.72974L1.62161 4.16217L2.2162 4.05406L1.94593 4.64866L2.2162 5.1892L1.62161 5.08109L1.18918 5.51352L1.08107 4.91893L0.540527 4.64866L1.08107 4.37839Z" fill="white"/>
    <path d="M2.10811 0.972989L2.21621 0.324341L2.64865 0.756773L3.24324 0.702719L2.97297 1.24326L3.24324 1.7838L2.64865 1.67569L2.21621 2.10812L2.10811 1.51353L1.56757 1.24326L2.10811 0.972989Z" fill="white"/>
    <path d="M4.10818 24.6487H3.08115V22.4865L2.54061 24.6487H1.7298L1.18926 22.4865V24.6487H0.162231V20.973H1.62169L2.10818 22.973L2.64872 20.973H4.10818V24.6487Z" fill="black"/>
    <path d="M5.62165 24.7027C5.02706 24.7027 4.64868 24.4324 4.64868 23.9459C4.64868 23.6216 4.81084 23.4054 5.08111 23.2432C5.24328 23.1891 5.45949 23.1351 5.78382 23.081C6.32436 22.9729 6.43246 22.9189 6.43246 22.7567C6.43246 22.6486 6.32436 22.5405 6.10814 22.5405C5.83787 22.5405 5.72976 22.6486 5.72976 22.8108V22.8648H4.75679C4.81084 22.1621 5.18922 21.8918 6.05409 21.8918C6.48652 21.8918 6.81084 21.9459 7.02706 22.1081C7.29733 22.2702 7.40544 22.4864 7.40544 22.9189V24.054C7.40544 24.3783 7.45949 24.4864 7.62165 24.5945H6.54057C6.48652 24.5405 6.48652 24.3783 6.43246 24.2702C6.21625 24.5945 6.00003 24.7027 5.62165 24.7027ZM5.89192 23.6216C5.67571 23.6756 5.5676 23.7297 5.5676 23.8918C5.5676 24 5.67571 24.1081 5.89192 24.1081C6.21625 24.1081 6.43246 23.8918 6.43246 23.5135V23.4054C6.32436 23.5135 6.21625 23.5135 5.89192 23.6216Z" fill="black"/>
    <path d="M10.8649 24.6487H9.89191V24.3244C9.72974 24.5946 9.45947 24.7568 9.0811 24.7568C8.54055 24.7568 8.21623 24.5406 8.00001 24C7.94596 23.7838 7.89191 23.5676 7.89191 23.2973C7.89191 22.4865 8.37839 21.8919 9.0811 21.8919C9.40542 21.8919 9.67569 22.0001 9.83785 22.2703V20.973H10.8649V24.6487ZM9.35137 22.6487C9.0811 22.6487 8.86488 22.919 8.86488 23.2973C8.86488 23.6757 9.02704 23.946 9.29731 23.946C9.56758 23.946 9.7838 23.6757 9.7838 23.2973C9.83785 22.8649 9.67569 22.6487 9.35137 22.6487Z" fill="black"/>
    <path d="M12.3243 23.5676C12.3783 23.8379 12.5405 24.0001 12.8648 24.0001C13.027 24.0001 13.1892 23.946 13.2432 23.7838H14.2162C14.1081 24.0001 14.054 24.1082 13.9459 24.2163C13.6756 24.4865 13.2432 24.6487 12.8108 24.6487C11.9459 24.6487 11.3513 24.0541 11.3513 23.2433C11.3513 22.4325 11.9459 21.8379 12.8108 21.8379C13.7297 21.8379 14.3243 22.4865 14.3243 23.5136H12.3243V23.5676ZM12.8108 22.5946C12.5405 22.5946 12.3783 22.7568 12.3243 23.0271H13.2432C13.2432 22.7028 13.081 22.5946 12.8108 22.5946Z" fill="black"/>
    <path d="M17.2974 21.6757H16.2704V20.973H17.2974V21.6757ZM17.2974 24.6487H16.2704V22H17.2974V24.6487Z" fill="black"/>
    <path d="M18.8649 22.3783C19.0812 22.054 19.3514 21.8918 19.7298 21.8918C20.3244 21.8918 20.7028 22.2702 20.7028 22.8648V24.6486H19.7298V23.1351C19.7298 22.9189 19.5676 22.7567 19.3514 22.7567C19.0812 22.7567 18.919 22.9189 18.919 23.2972V24.6486H17.892V22H18.8109L18.8649 22.3783Z" fill="black"/>
    <path d="M26.2703 23.1892C26.2703 23.7298 26.1622 24 25.8919 24.2703C25.5676 24.5946 25.1892 24.7027 24.5946 24.7027C24.1081 24.7027 23.7297 24.5946 23.4595 24.4325C23.027 24.1622 22.8649 23.8379 22.8649 23.1352V20.9189H24V23.1892C24 23.6216 24.1622 23.8379 24.5946 23.8379C24.973 23.8379 25.1892 23.6216 25.1892 23.1892V20.9189H26.3243V23.1892H26.2703Z" fill="black"/>
    <path d="M28.8108 22.0541C28.7567 21.8378 28.6486 21.7297 28.3243 21.7297C28.1081 21.7297 27.9459 21.8378 27.9459 21.9459C27.9459 22.0541 28.054 22.1081 28.5405 22.2162L28.9189 22.3243C29.6756 22.4865 30.1081 22.9189 30.1081 23.4595C30.1081 24.2162 29.4594 24.7027 28.4324 24.7027C27.4594 24.7027 26.7567 24.2162 26.7567 23.4595V23.4054H27.8918C27.9459 23.7297 28.1081 23.8378 28.4864 23.8378C28.7567 23.8378 28.9729 23.7297 28.9729 23.5676C28.9729 23.4054 28.9189 23.4054 28.5405 23.2973L28.2702 23.1892C27.6216 23.027 27.3513 22.8649 27.1891 22.7027C27.027 22.5405 26.9189 22.2703 26.9189 22C26.9189 21.2973 27.4594 20.8649 28.3783 20.8649C29.4054 20.8649 29.8918 21.1892 29.9459 22.0541H28.8108Z" fill="black"/>
    <path d="M31.2433 24.6487H30.1622L31.5135 20.973H32.6487L34 24.6487H32.8649L32.7027 24.1082H31.4595L31.2433 24.6487ZM32.4324 23.3514L32.1081 22.2703L31.7297 23.3514H32.4324Z" fill="black"/>
  </svg>
</template>
<script>
	export default {
		name: 'IconFlagUsa'
	}
</script>