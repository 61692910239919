<template>
  <div class="app-main">
    <loading
      :active.sync="loading"
      is-full-page>
    </loading>
    <customize
      v-if="!loading && isPDP"
      :openDisplayCart="openDisplayCart"
      :closeDisplayCart="closeDisplayCart">
    </customize>
    <div
      class="overlay__bg minicart"
      :class="{'visibled': display_cart_opened}"
      :key="'display-cart'"
      @click.prevent="closeDisplayCart">
    </div>
    <minicart
      v-show="display_cart_opened"
      :continue_shopping="closeDisplayCart">
    </minicart>
  </div>
</template>

<script>
import $ from 'jquery'
import { getProduct } from '@/api/product'
import { getFreightOptions } from '@/api/cart'
import { getSettings } from '@/api/settings'
import { getDraftOrder } from '@/api/order'
import { completeDraftOrder } from '@/api/order'
import Loading from 'vue-loading-overlay'
import Customize from '@/components/customize'
import Minicart from '@/components/minicart'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  name: 'App',
  components: {
    Loading,
    Customize,
    Minicart
  },
  data () {
    return {
      loading: false,
      isPDP: false,
      display_cart_opened: false,
      draft_order_id: null,
      invoice_url: null
    }
  },
  created () {
    this.$root.$on('set-loader', () => {
      this.loading = true
    })
    this.$root.$on('clear-loader', () => {
      this.loading = false
    })
    this.loadProduct()
    // this.loadSettings()
    this.loadFreightoptions()
    this.loadDraftOrder()
  },
  methods: {
    loadProduct () {
      let id = window.PRODUCT_ID
      if (id != 'empty') {
        this.loading = true
        this.isPDP = true

        getProduct(id).then(res => {
          let product = res.data.product
          this.$store.dispatch('product/set', product)
          this.$store.dispatch('order/setVariant', product.variants.edges[0].node)
          this.$store.dispatch('template/set_template', res.data.template[0])

          if(window.logged_in != null) {
            this.$store.dispatch('cart/setLoggedInUser', window.logged_in)
          }
        }).catch(err => {
          console.log(err)
        }).then(() => {
          this.loading = false
        })
      }
    },
    loadDraftOrder () {
      let draft_order_id = window.DRAFT_ORDER_ID
      if (draft_order_id != 'empty') {
        this.loading = true
        getDraftOrder({draft_order_id: draft_order_id}).then(res => {
          var draft_order = res.data.draft_order
          if(draft_order != null) {
            this.invoice_url = draft_order.invoice_url
            if(draft_order.tags.includes("signed")) {
              window.signedDraftOrder(this.invoice_url)
            } else {
              this.draft_order_id = res.data.draft_order.id
              window.signDraftOrder = this.signDraftOrder
              window.setDraftOrderName(res.data.draft_order.name)
            }
          }
        }).catch(err => {
          console.log(err)
        }).then(() => {
          this.loading = false
        })
      }
    },
    loadFreightoptions () {
      getFreightOptions().then(res => {
        const freightOptions = res.data.freightoptions
        this.$store.dispatch('cart/setFreightOptionList', freightOptions)
      }).catch(err => {
        console.log('load freight options error: ', err)
      })
    },
    loadSettings() {
      getSettings().then(res => {
        const settings = res.data.settings
        this.$store.dispatch('settings/setSettings', settings)
      }).catch(err => {
        console.log('load settings error: ', err)
      })
    },
    signDraftOrder() {
      if(this.draft_order_id != null) {
        this.loading = true

        completeDraftOrder({ draft_order_id: this.draft_order_id }).then(res => {
          if(res.data.result) {
            window.signedDraftOrder(this.invoice_url);
          }
        }).catch(err => {
          console.log(err)
        }).then(() => {
          this.loading = false
        })
      }
    },
    openDisplayCart () {
      this.display_cart_opened = true
      $('#shopify-section-header .header').css('z-index', '-1')
      $('body').css({'position': 'fixed', 'width': '100%'});
    },
    closeDisplayCart () {
      this.display_cart_opened = false
      $('#shopify-section-header .header').css('z-index', '101')
      $("body").css({'position': 'inherit', 'width': 'inherit'});
      setTimeout(() => {
        $("body").removeClass("no-scroll");
      }, 350);
    }
  }
}
</script>