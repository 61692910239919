<template>
  <div class="form__selections-view">
    <h4>Your selections</h4>
    <ul>
      <li
        v-for="(option, index) in options"
        :key="index"
      >{{option.group}}: {{option.label}}</li>
      <!-- <li>Shipping method: Free ground freight</li> -->
      <li v-if="header_option.allow_note"><span v-html="header_option.header_title"></span>: {{header_option.header_text}}</li>
    </ul>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'FormSelection',
  computed: {
    ...mapGetters({
      quantity: 'order/quantity',
      options: 'order/custom_options',
      header_option: 'order/header_option',
    })
  }
}
</script>