<template>
  <div>
    <div
      class="overlay__bg make-selection"
      :key="'make-selection'"
      :class="{'visibled': is_opened}"
      @click.prevent="closeSelection">
    </div>
    <div
      class="product__section-overlay"
      :class="{'visibled': is_opened}">
      <a
        class="close-selections"
        @click.prevent="closeSelection">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8 16L9.41333 14.5867L3.82667 9L16 9L16 7L3.82667 7L9.41333 1.41333L8 -6.99382e-07L6.99382e-07 8L8 16Z" />
        </svg>
        Back to product details
      </a>
      <h1>Make Your Selections</h1>
      <div class="form-body">
        <div class="loading-spin">
          <loading
            :active.sync="loading"
            :is-full-page="false"
          />
        </div>
        <input-quantity :inSelect="1"></input-quantity>
        <div
          v-for="(group, index) in template.groups"
          :key="index"
          class="form__row selector-wrapper js product-form__item">
          <label class="form__label" :for="`group-option-selector-${index}`">
            {{ index + 2 }}. {{group.label}}
          </label>
          <div class="select">
            <multiselect
              :custom-label="customSelectLabel"
              :placeholder="`Select ${group.label}`"
              :options="activeOptions(group.dattributes)"
              :show-labels="false"
              :searchable="false"
              :allow-empty="false"
              :multiple="false"
              v-model="option_values[index]"
              @select="setAddOn($event, index, group.id)">
            </multiselect>
          </div>
          <div
            class="form__errors"
            v-if="!customized(group.label)">
            This option requires changes. Click to review.
          </div>
        </div>
        <div
          v-if="productData.allow_note"
          class="form__row selector-wrapper js product-form__item">
          <span v-html="productData.note_header"></span>
          <textarea v-model.lazy="headerText" class="header_text"></textarea>
        </div>
        <form-selection-table v-if="isAdmin" :itemId="variant.sku" />
      </div>
      <div class="form__actions custom-selection__actions">
        <div class="custom-selection__actions--inner">
          <button type="button" class="save_to_wishlist" :data-wk-product="productId">
            <icon-wishlist v-if="!wishlistType" />
            <icon-wishlist-fill v-else/>
            <span v-if="!wishlistType">Save to wish list</span>
            <span v-else>Remove from wish list</span>
          </button>
          <!-- <a class="save_to_wishlist"
            @click.prevent="addToWhishlist">
            <icon-heart/>
            Save to Wish List
          </a> -->
          <div class="form__actions-right">
            <div class="total__cost">
              <div class="total__cost-prifix">Total Cost</div>
              <div class="total__cost-price">{{calculated_price | money}}</div>
            </div>
            <button
              class="btn form__btn btn--green custom-selection__action--desktop"
              :disabled="!fully_customized"
              @click.prevent="saveSelection">
              <span>Save Selections</span>
            </button>
          </div>
        </div>
        <div class="custom-selection__actions--mobile">
          <button
            class="btn form__btn btn--green"
            :disabled="!fully_customized"
            @click.prevent="saveSelection">
            <span>Save Selections</span>
          </button>
        </div>
      </div>
    </div>
    <div
      class="overlay__bg added_confirm"
      :class="{'visibled': addtocart_confirm_opened}"
      @click.prevent="closeConfirm">
    </div>
    <div
      class="added_confirm-overlay"
      v-show="addtocart_confirm_opened">
      <div class="addedCart">
        <div class="added_img">
          <img :src="productData.images.edges[0].node.originalSrc" />
        </div>
        <div class="added_product_info">
          <p>Item ID # {{ this.variant.sku }}</p>
          <a :href="productData.onlineStoreUrl" :target="_blank">{{productData.title}}</a>
          <span>Added to Cart</span>
        </div>
      </div>
      <div class="confirm-buttons">
        <div>
          <a class="btn form__btn btn--block" @click.prevent="continueShopping">continue shopping</a>
        </div>
        <div>
          <a class="btn form__btn btn--block" @click.prevent="viewDisplayCart">view cart</a>
        </div>
      </div>
      <div class="related-products" v-if="relatedItems.length > 0">
        <h2>Customer Who Shopped For {{this.productData.title}} Also Purchased Items Below</h2>
        <div class="product-list">
          <div class="product" v-for="item in relatedItems.slice(0, 3)" :key="item.id">
            <img :src="item.image" />
            <a :href="item.url">{{item.title}}</a>
            <span>Item ID #{{item.sku}}</span>
            <p v-if="calculatedPrice(item)">From {{calculatedPrice(item) | money }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="form__actions">
      <button
        class="btn form__btn btn--block"
        :disabled="!customizable"
        :class="{
          'inactive': !customizable,
          'customized': fully_customized
        }"
        @click.prevent="openSelection">
        <span v-if="fully_customized && customizable">Edit selections</span>
        <span v-else>VIEW OPTIONS & PRICING</span>
      </button>
      <button class="btn form__btn btn--block add-to-cart"
        :class="{'inactive': !fully_customized && customizable}"
        :disabled="!fully_customized && customizable"
        @click.prevent="addToCart">
        <span>Add to cart</span>
        <span v-if="!fully_customized && customizable">(Make Selections first)</span>
      </button>
      <a @click.prevent="openQuote" class="request-quote">
        <icon-request-quote />
        <span>Request a Quote</span>
      </a>
      <!--<a @click.prevent="addToWhishlist" class="save-wishlist">
        <icon-wishlist />
        <span>Save to wish list</span>
      </a>-->
      <button type="button" class="save-wishlist" :data-wk-product="productId">
        <icon-wishlist v-if="!wishlistType" />
        <icon-wishlist-fill v-else/>
        <span v-if="!wishlistType">Add to wish list</span>
        <span v-else>Remove from wish list</span>
      </button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import $ from 'jquery'
import Loading from 'vue-loading-overlay'
import priceMixin from '@/mixins/price'
import InputQuantity from '@/components/quantity'
import IconHeart from '@/components/icons/icon-heart'
import IconRequestQuote from '@/components/icons/icon-request-quote'
import IconWishlist from '@/components/icons/icon-wishlist'
import IconWishlistFill from '@/components/icons/icon-wishlist-fill'
import FormSelectionTable from '@/components/form-selection-table'
import 'vue-loading-overlay/dist/vue-loading.css'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'

export default {
  name: 'FormActions',
  computed: {
    ...mapGetters({
      variant_id: 'order/variant_id',
      variant: 'product/variant',
      template: 'template/get_template',
      group: 'template/group_by_id',
      exceptGroupList: 'template/group_label_list',
      productData: 'product/get',
      custom_options: 'order/custom_options',
      except_list: 'order/except_list',
      cart_count: 'cart/get_cart_count',
      optionsSaved: 'order/is_saved',
      loggedUser: 'cart/get_logged_in_user'
    }),
    relatedItems () {
      return window.relatedProducts || []
    }
  },
  props: {
    openDisplayCart: {
      type: Function
    },
    openQuote: {
      type: Function
    }
  },
  mixins: [ priceMixin ],
  components: {
    InputQuantity,
    IconHeart,
    IconRequestQuote,
    IconWishlist,
    IconWishlistFill,
    Loading,
    FormSelectionTable,
    Multiselect
  },
  data () {
    return {
      noselected: 'noselected',
      loading: false,
      is_opened: false,
      addtocart_confirm_opened: false,
      selectedOptions: [],
      productId: window.PRODUCT_ID,
      wishlistType: false,
      isAdmin: false,
      headerText: "",
      option_values: {},
      //lastSelected: 0
    }
  },
  mounted () {
    if(this.loggedUser != null && this.loggedUser.includes("admin")) {
      this.isAdmin = true
    }
    window.setWishlistState = this.changeWishlistState
    window.getWishlistState = this.getWishlistState

    if (window.wkManager && window.wkManager.wk && window.wkManager.wk.getProduct) {
      var product = window.wkManager.wk.getProduct(this.productId)

      if(product != null) {
        this.wishlistType = product.in_wishlist
      }
    }

    this.initSelection()
  },
  methods: {
    changeWishlistState(state) {
      this.wishlistType = state
    },
    getWishlistState() {
      return this.wishlistType
    },
    calculatedPrice (item) {
      const priceTag = item.tags.find(tag => tag.includes('demo-price'))

      if (priceTag) {
        return parseFloat(priceTag.replaceAll('demo-price:', ''))
      } else {
        return false
      }
    },
    isMadeSelection() {
      if (!this.fully_customized && this.customizable) {
        return false
      }
      return true
    },
    openSelection () {
      this.is_opened = true

      if(!this.isMadeSelection()) {
        this.option_values = {}
        this.checkGroupOneAttribute()
      }

      $('.product__details').css('z-index', -1)
      $('#shopify-section-header .header').css('z-index', '-1')
      $('.wrapper').css({'position': 'fixed', 'width': '100%'});
    },
    closeSelection () {
      this.is_opened = false
      if(!this.fully_customized || !this.optionsSaved) {
        this.$store.dispatch('order/initCustomization')
      }
      $('.product__details').css('z-index', 'initial')
      $('#shopify-section-header .header').css('z-index', '101')
      $('.wrapper').css({'position': 'inherit', 'width': 'inherit'});
    },
    openConfirm () {
      this.addtocart_confirm_opened = true
      $('.product__details').css('z-index', -1)
      $('#shopify-section-header .header').css('z-index', '-1')
      $('.wrapper').css({'position': 'fixed', 'width': '100%'});
    },
    closeConfirm () {
      this.addtocart_confirm_opened = false
      $('.product__details').css('z-index', 'initial')
      $('#shopify-section-header .header').css('z-index', '101')
      $('.wrapper').css({'position': 'inherit', 'width': 'inherit'});
    },
    continueShopping () {
      this.closeConfirm()
      // this.openSelection()
    },
    viewDisplayCart () {
      this.closeConfirm()
      this.openDisplayCart()
    },
    isActiveOption (item) {
      if (!this.except_list.includes(item.id)) {
        return 'disabled'
      }
    },
    activeOptions (options) {
      return options.filter(op => !this.except_list.includes(op.id))
    },
    checkGroupOneAttribute () {
      this.template.groups.map((gr, index) => {
        const activeAttributes = this.activeOptions(gr.dattributes)
        if (activeAttributes.length == 1) {
          this.setAutoOneAddon(index, gr, activeAttributes[0])
          this.option_values[index] = activeAttributes[0]
        }
      })
    },
    async setAutoOneAddon (index, group, item) {
      if (item) {
        item['group'] = group.label

        // get excepts for selected item
        const drellation = group.drellations.find(dr => dr.dattribute_id == item.id)
        const newExcepts = drellation.excepts == '' ? [] : drellation.excepts.split(',').map(ex => {
          return {
            groupId: drellation.group_id,
            groupLabel: group.label,
            exceptId: parseInt(ex)
          }
        })
        try {
          item.index = index
          item.table_row = drellation.table_row_option
          item.table_row_options = drellation.table_row_option.split(',,').map(e => Number(e))
          item.table_row_vendors = drellation.table_row_vendor.split(',,').map(e => Number(e))

          await this.$store.dispatch('order/upsert_customization', item)
          await this.$store.dispatch('order/setExcepts',
            {
              groupId: drellation.group_id,
              groupLabelList: this.exceptGroupList(drellation.excepts),
              exceptData: newExcepts
            }
          )
        } catch (error) {
          console.log('Error in upsert customization: ', error)
        }
      }
    },
    initSelection () {
      if (!this.customizable) {
        this.template.groups.map((group, index) => {
          let item = group.dattributes[0]
          if (item) {
            item['group'] = group.label
            item['index'] = index
            const drellation = group.drellations.find(dr => dr.dattribute_id == item.id)
            item['table_row'] = drellation.table_row_option
            item['table_row_options'] = drellation.table_row_option.split(',,').map(e => Number(e))
            item['table_row_vendors'] = drellation.table_row_vendor.split(',,').map(e => Number(e))

            const newExcepts = drellation.excepts == '' ? [] : drellation.excepts.split(',').map(ex => {
              return {
                groupId: drellation.group_id,
                groupLabel: group.label,
                exceptId: parseInt(ex)
              }
            })

            this.$store.dispatch('order/upsert_customization', item)
            this.$store.dispatch('order/setExcepts',
              {
                groupId: drellation.group_id,
                groupLabelList: this.exceptGroupList(drellation.excepts),
                exceptData: newExcepts
              }
            )
          }
        })
      }
    },
    customSelectLabel(item) {
      var text =""
      if(item.price_type && item.price > 0) {
        text = item.label + " (" + item.price + "% Upcharge)"
      } else if(!item.price_type && item.price > 0) {
        text = item.label + " (+ $" + item.price.toFixed(2) + ")"
      } else {
        text = item.label
      }
      return text
    },
    async setAddOn(option, index, group_id) {
      let item_id = option.id
      let group = this.group(group_id)
      let item = group.dattributes.find(i => i.id === +item_id)

      if (item) {
        item['group'] = group.label
        // get excepts for selected item
        const drellation = group.drellations.find(dr => dr.dattribute_id == item.id)
        const newExcepts = drellation.excepts == '' ? [] : drellation.excepts.split(',').map(ex => {
          return {
            groupId: drellation.group_id,
            groupLabel: group.label,
            exceptId: parseInt(ex)
          }
        })

        try {
          item.index = index
          item.table_row = drellation.table_row_option
          item.table_row_options = drellation.table_row_option.split(',,').map(e => Number(e))
          item.table_row_vendors = drellation.table_row_vendor.split(',,').map(e => Number(e))

          const customOptions = await this.$store.dispatch('order/upsert_customization', item)
          await this.$store.dispatch('order/setExcepts',
            {
              groupId: drellation.group_id,
              groupLabelList: this.exceptGroupList(drellation.excepts),
              exceptData: newExcepts
            }
          )
          this.checkGroupOneAttribute()
        } catch (error) {
          console.log('Error in upsert customization: ', error)
        }
      }
    },
    /*async setAddOn (index, evt) {
      let group_id = evt.target.dataset.group
      let item_id = evt.target.value
      if (item_id != 'noselected') {
        this.lastSelected = evt.target.selectedIndex
      }
      // console.log('last selected option: ', this.lastSelected)
      if (item_id == 'noselected') {
        evt.target.selectedIndex = this.lastSelected
        return false
      } else {
        let group = this.group(group_id)
        let item = group.dattributes.find(i => i.id === +item_id)
        console.log('change attribute: ', group_id, item_id)
        if (item) {
          item['group'] = group.label

          // get excepts for selected item
          const drellation = group.drellations.find(dr => dr.dattribute_id == item.id)
          const newExcepts = drellation.excepts == '' ? [] : drellation.excepts.split(',').map(ex => {
            return {
              groupId: drellation.group_id,
              groupLabel: group.label,
              exceptId: parseInt(ex)
            }
          })
          try {
            item.index = index
            item.table_row = Number(drellation.table_row_option)
            const customOptions = await this.$store.dispatch('order/upsert_customization', item)
            await this.$store.dispatch('order/setExcepts',
             {
                groupId: drellation.group_id,
                groupLabelList: this.exceptGroupList(drellation.excepts),
                exceptData: newExcepts
              }
            )
            //this.checkGroupOneAttribute()
          } catch (error) {
            console.log('Error in upsert customization: ', error)
          }
        }
      }
    },*/
    saveSelection () {
      this.$store.dispatch('order/setHeaderOption', {
        allow_note: this.productData.allow_note,
        header_text: this.headerText,
        header_title: this.productData.note_header})
      this.$store.dispatch('order/setSaved')
      this.is_opened = false
      $('.product__details').css('z-index', 'initial')
      $('#shopify-section-header .header').css('z-index', '101')
      $('.wrapper').css({'position': 'inherit', 'width': 'inherit'})
    },
    customized (group_label) {
      return this.custom_options.map(opt => opt.group).includes(group_label)
    },
    async addToCart () {
      if (!this.fully_customized && this.customizable) {
        return false
      }

      let cartItem = {
        product_title: this.productData.title,
        product_url: this.productData.onlineStoreUrl,
        product_img_url: this.productData.images.edges[0].node.originalSrc,
        product_id: this.productData.id.split('/').slice(-1).pop(),
        collections: this.productData.collections.edges.map(collectionItem => {
          return collectionItem.node.id.split('/').slice(-1).pop()
        }),
        variant_id: this.variant_id,
        selected_options: this.variant.selectedOptions,
        custom_options: this.custom_options,
        quantity: this.quantity,
        sku: this.variant.sku,
        tags: this.productData.tags,
        shipping_summary: this.productData.metafield ? this.productData.metafield.value : "",
        ship_price_percent: this.productData.ship_price_percent,
        maximum_shipping_quantity: this.productData.maximum_shipping_quantity,
        minimal_shipping_quantity: this.productData.minimal_shipping_quantity,
        allow_note: this.productData.allow_note,
        header_text: this.headerText,
        header_title: this.productData.note_header,
        original_price: this.original_price,
        calculated_item_price: this.calculated_item_price,
        calculated_price: this.calculated_price,
        free_ground: this.productData.tags.includes('free-ground') ? true : false,
        is_freight: this.productData.tags.includes('is-freight') ? true : false,
        wishlisted: false,
        fedex_price: 0,
        in_wishlist: this.wishlistType
      }

      try {
        const res = await this.$store.dispatch('cart/addCart', cartItem)
        $('.cart-count').text(this.cart_count)
        this.openConfirm()
      } catch (error) {
        console.log(error)
      }
    },
    async addToWhishlist () {
      this.loading = true
      let wishItem = {
        product_title: this.productData.title,
        product_url: this.productData.onlineStoreUrl,
        product_img_url: this.productData.images.edges[0].node.originalSrc,
        product_id: this.productData.id.split('/').slice(-1).pop(),
        collections: this.productData.collections.edges.map(collectionItem => {
          return collectionItem.node.id.split('/').slice(-1).pop()
        }),
        variant_id: this.variant_id,
        selected_options: this.variant.selectedOptions,
        custom_options: this.custom_options,
        sku: this.variant.sku,
        calculated_item_price: this.calculated_item_price,
        free_ground: this.productData.tags.includes('free-ground') ? true : false,
        is_freight: this.productData.tags.includes('is-freight') ? true : false,
      }
      try {
        const res = await this.$store.dispatch('wishlist/addWish', wishItem)
        setTimeout(() => {
          this.loading = false
          console.log('add wishlist response: ', res)
        }, 800)
      } catch (err) {
        this.loading = false
        console.log(err)
        alert('Sorry, adding this selection into wishlist is failed!')
      }
    }
  }
}
</script>