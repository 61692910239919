<template>
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.00001 14.4542L7.15417 13.6842C4.15001 10.96 2.16667 9.16333 2.16667 6.95833C2.16667 5.16167 3.57834 3.75 5.37501 3.75C6.39001 3.75 7.36417 4.2225 8.00001 4.96917C8.63584 4.2225 9.61001 3.75 10.625 3.75C12.4217 3.75 13.8333 5.16167 13.8333 6.95833C13.8333 9.16333 11.85 10.96 8.84584 13.69L8.00001 14.4542Z" fill="#616D83"/>
    <path d="M10.7 3.4599C9.656 3.4599 8.654 3.95182 8 4.72917C7.346 3.95182 6.344 3.4599 5.3 3.4599C3.452 3.4599 2 4.92958 2 6.80007C2 9.09568 4.04 10.9662 7.13 13.8084L8 14.6039L8.87 13.8023C11.96 10.9662 14 9.09568 14 6.80007C14 4.92958 12.548 3.4599 10.7 3.4599ZM8.06 12.9035L8 12.9642L7.94 12.9035C5.084 10.286 3.2 8.55518 3.2 6.80007C3.2 5.58546 4.1 4.67451 5.3 4.67451C6.224 4.67451 7.124 5.27574 7.442 6.10774H8.564C8.876 5.27574 9.776 4.67451 10.7 4.67451C11.9 4.67451 12.8 5.58546 12.8 6.80007C12.8 8.55518 10.916 10.286 8.06 12.9035Z" fill="#616D83"/>
  </svg>
</template>
<script>
export default {
  name: 'IconHeartFill'
}
</script>