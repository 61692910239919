import { createOrder, createQuote } from '@/api/order'

const order = {
  namespaced: true,
  state: {
    quantity: 1,
    variant_id: null,
    custom_options: [],
    display_options: [],
    current_excepts: [],
    except_list: [],
    saved: false,
    header_option: {}
  },
  actions: {
    setHeaderOption({commit}, option) {
      commit('SET_HEADER_OPTION', option)
    },
    setQuantity ({commit}, quantity) {
      commit('SET_QUANTITY', quantity)
    },
    setVariant ({commit}, variant) {
      commit('SET_VARIANT', variant)
    },
    initCustomization ({commit}) {
      commit('SET_INIT')
    },
    setSaved({commit}) {
      commit('SET_SAVED')
    },
    upsert_customization ({commit}, option) {
      commit('UPSERT_OPTION', option)
    },
    setExcepts ({commit}, excepts) {
      commit('SET_EXCEPTS', excepts)
    },
    create_order({commit}, data) {
      return new Promise((resolve, reject) => {
        createOrder(data).then(res => {
          resolve(res.data)
        }).catch(err => {
          reject(err)
        })
      })
    },
    async createQuote ({ commit, state, getters }, data) {
      try {
        const newQuote = await createQuote({ quoteDetail: state.custom_options, contactDetail: data.contactData, variantId: state.variant_id, quantity: state.quantity, header_option: state.header_option, shipping_summary: data.shipping_summary })
        return newQuote.data
      } catch (error) {
        console.log('error generated when creating new quote')
      }
    },
  },
  mutations: {
    SET_HEADER_OPTION: (state, option) => {
      state.header_option = option
    },
    SET_QUANTITY: (state, quantity) => {
      if (quantity) {
        state.quantity = quantity
      }
    },
    SET_VARIANT: (state, variant) => {
      if (variant) {
        let variant_id = variant.id.split('/')[variant.id.split('/').length - 1]
        state.variant_id = variant_id
      } else {
        state.variant_id = null
      }
    },
    SET_INIT: (state) => {
      state.custom_options = []
      state.except_list = []
    },
    SET_SAVED: (state) => {
      state.saved = true
    },
    UPSERT_OPTION: (state, option) => {
      let options = state.custom_options
      if (option) {
        // Here option.group is the label of group
        options = options.filter(opt => opt.group !== option.group)
        options = [...options, option]
        options = options.sort((data1, data2) => {
          return data1.index - data2.index
        })
        state.custom_options = options
        state.display_options = []
        var tRow = -1
        var indexOffset = 1;
        for (var i = 0; i < options.length; i ++) {
          if (tRow == options[i].table_row) {
            if (options[i].attribute_code != "") {
              state.display_options[state.display_options.length - indexOffset].attribute_code += "-" + options[i].attribute_code.split(",,")[0]
            }
            if (options[i].mfgcode != "") {
              var splits = options[i].mfgcode.split(",,")
              var dsplits = options[i].description.split(",,")
              var vsplits = options[i].vendor_sku.split(",,")
              if(splits.length > 1) {
                state.display_options[state.display_options.length - indexOffset].mfgcode += "-" + splits[0]
                if(dsplits.length > 0) {
                  state.display_options[state.display_options.length - indexOffset].description += ", " + dsplits[0]
                }
                state.display_options.push({...options[i]})
                indexOffset++
                state.display_options[state.display_options.length - 1].mfgcode = splits[1]
                state.display_options[state.display_options.length - 1].attribute_code = ""
                state.display_options[state.display_options.length - 1].description = (dsplits.length > 1 ? dsplits[1] : "")
                state.display_options[state.display_options.length - 1].vendor_sku = (vsplits.length > 1 ? vsplits[1] : "")
                state.display_options[state.display_options.length - 1].postal_code = "N/A"
              } else {
                if (options[i].mfgcode.indexOf("/") == -1) {
                  state.display_options[state.display_options.length - indexOffset].mfgcode += "-" + options[i].mfgcode
                  state.display_options[state.display_options.length - indexOffset].description += ", " + options[i].description.split(",,")[0]
                  var opt = options[i].vendor_sku.split(",,")[0]
                  if(state.display_options[state.display_options.length - indexOffset].vendor_sku.indexOf(opt) == -1) {
                    state.display_options[state.display_options.length - indexOffset].vendor_sku += ", " + opt
                  }
                } else {
                  state.display_options.push({...options[i]})
                  indexOffset++
                  state.display_options[state.display_options.length - 1].mfgcode = options[i].mfgcode
                  state.display_options[state.display_options.length - 1].attribute_code = ""
                  state.display_options[state.display_options.length - 1].description = options[i].description.split(",,")[0]
                  state.display_options[state.display_options.length - 1].vendor_sku = options[i].vendor_sku.split(",,")[0]
                  state.display_options[state.display_options.length - 1].postal_code = "N/A"
                }
              }

              if(state.display_options[state.display_options.length - indexOffset].mfgcode.charAt(0) == "-") {
                state.display_options[state.display_options.length - indexOffset].mfgcode = state.display_options[state.display_options.length - indexOffset].mfgcode.substr(1)
              }

              if(state.display_options[state.display_options.length - indexOffset].description.charAt(0) == ",") {
                state.display_options[state.display_options.length - indexOffset].description = state.display_options[state.display_options.length - indexOffset].description.substr(2)
              }
              if(state.display_options[state.display_options.length - indexOffset].vendor_sku.charAt(0) == ",") {
                state.display_options[state.display_options.length - indexOffset].vendor_sku = state.display_options[state.display_options.length - indexOffset].vendor_sku.substr(2)
              }
            }
          } else {
            tRow = options[i].table_row
            indexOffset = 1
            state.display_options.push({...options[i]})
            // Danger
            state.display_options[state.display_options.length - 1].attribute_code = state.display_options[state.display_options.length - 1].attribute_code.split(",,")[0]
            state.display_options[state.display_options.length - 1].mfgcode = state.display_options[state.display_options.length - 1].mfgcode.split(",,")[0]
            state.display_options[state.display_options.length - 1].description = state.display_options[state.display_options.length - 1].description.split(",,")[0]
            state.display_options[state.display_options.length - 1].vendor_sku = state.display_options[state.display_options.length - 1].vendor_sku.split(",,")[0]
          }
        }
      }
    },
    SET_EXCEPTS: (state, excepts) => {
      let removedExceptList = state.except_list.filter(ex => ex.groupId != excepts.groupId)
      let newExceptList = removedExceptList.concat(excepts.exceptData)
      // Check if options includes except list
      let options = state.custom_options
      options = options.filter(opt => !excepts.groupLabelList.includes(opt.group))
      state.custom_options = options
      state.except_list = newExceptList
    }
  },
  getters: {
    quantity (state) {
      return state.quantity
    },
    variant_id (state) {
      return state.variant_id
    },
    custom_options (state) {
      return state.custom_options
    },
    header_option(state) {
      return state.header_option
    },
    except_list (state) {
      return state.except_list.map(ex => ex.exceptId)
    },
    is_saved (state) {
      return state.saved
    },
    display_options(state) {
      return state.display_options
    }
  }
}

export default order
