<template>
  <div>
    <table class="option-select-table">
      <thead>
        <tr>
          <th>Item ID</th>
          <th>Description</th>
          <th>Vendor</th>
          <th>Shipping Weight</th>
          <th>Shipping Dimensions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, index) in tableRows" :key="index">
          <td>
            <div class="item-header" v-if="row.attributeCodes.length > 0">{{row.attributeCodes.join('-')}}</div>
            <span class="item-header-addition" v-if="row.vendorCodes.length > 0">{{row.vendorCodes.join('-')}}</span>
          </td>
          <td>
            <ul v-if="row.descriptions.length > 0">
              <li v-for="(description, key) in row.descriptions" :key="key" v-html="description"></li>
            </ul>
          </td>
          <td>{{row.vendorSkus.join(', ')}}<br/><br/></span>Postal Code: {{row.postalCode.join(', ')}}</td>
          <td>{{row.weight}}</td>
          <td>{{row.dimension}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'FormSelectionTable',
  computed: {
    ...mapGetters({
      options: 'order/custom_options',
      display_options: 'order/display_options',
    }),
    rowNumbers () {
      const rowNumbers = this.options.map(option => {
        const tableRowOptions =(option?.table_row_options || [])
        const tableRowVendors =(option?.table_row_vendors || [])
        return [...tableRowOptions, ...tableRowVendors]
      }).flat()

      return [...new Set(rowNumbers)].sort((a, b) => a - b)
    },
    tableRows () {
      const rows = this.rowNumbers.map(rowIndex => {
        const options = this.options.filter(option => option.table_row_options && option.table_row_options.includes(rowIndex) || option.table_row_vendors && option.table_row_vendors.includes(rowIndex))
        const postalCode = [...new Set(options.map(option => option.postal_code))]

        let attributeCodes = options.map(option => {
          const optionIndex = option.table_row_options.findIndex(e => e === rowIndex)
          return option.attribute_code.split(',,')[optionIndex]
        }).filter(e => !!e)

        if (rowIndex === 1) {
          attributeCodes = [this.itemId, ...attributeCodes]
        }

        const vendorCodes = options.map(option => {
          const optionIndex = option.table_row_vendors.findIndex(e => e === rowIndex)
          return option.mfgcode.split(',,')[optionIndex]
        }).filter(e => !!e)

        const descriptions = options.map(option => {
          const descriptionIndexes = [...new Set([...option.table_row_options, ...option.table_row_vendors])].sort((a, b) => a - b)
          const descriptionIndex = descriptionIndexes.findIndex(e => e === rowIndex)

          return option.description.split(',,')[descriptionIndex]
        }).filter(e => !!e)


        const vendorSkus = options.map(option => {
          const skuIndexes = [...new Set([...option.table_row_options, ...option.table_row_vendors])].sort((a, b) => a - b)
          const skuIndex = skuIndexes.findIndex(e => e === rowIndex)

          return option.vendor_sku.split(',,')[skuIndex]
        }).filter(e => !!e)

        const weights = options.filter(option => option.weight > 0)
          .map(e => `${e.weight} LBS`)

        const dimensions = options.filter(option => option.width !== 0 && option.length !== 0 && option.girth !== 0)
          .map(e => `${e.weight}" x ${e.length}" x ${e.girth}"`)

        return {
          rowNumber: rowIndex,
          postalCode: postalCode,
          attributeCodes,
          vendorCodes,
          vendorSkus: [...new Set(vendorSkus)],
          descriptions,
          weight: weights.length > 0 ? weights.join(', ') : 'N/A',
          dimension: dimensions.length > 0 ? dimensions.join(', ') : 'N/A'
        }
      }).filter(row => row.rowNumber > 0)

      return rows
    }
  },
  props: {
    itemId: {
      type: String
    },
  },
  methods: {
  }
}
</script>